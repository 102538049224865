import { useMemo, useState } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import Select from 'react-select';

import {
  type ModelsEmailAddress,
  type ModelsMessageTemplateEmail,
} from '@lp-lib/api-service-client/public';
import { EmailTemplateMarkdown, renderEmail } from '@lp-lib/email-templates';

import config from '../../../config';
import { useInstance } from '../../../hooks/useInstance';
import { apiService } from '../../../services/api-service';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { EMAIL_PATTERN } from '../../Access';
import { useTriggerSendTestModal } from '../MessageSendTest';
import { MESSAGE_TEMPLATE_EMAIL_FROM_LIST } from './config';
import { MessageTemplateEmailButtonsField } from './MessageTemplateEditorEmailButton';
import { MessageTemplateMarkdownInput } from './MessageTemplateMarkdown';
import { type MessageTemplateFormData } from './Shared';
import { MessageTemplateUtils } from './utils';

function SendTestButton(props: {
  template: ModelsMessageTemplateEmail | null;
  notificationSettingsLink?: string | null;
}) {
  const { template, notificationSettingsLink } = props;

  const triggerSendTestModal = useTriggerSendTestModal();
  const { trigger } = useFormContext<MessageTemplateFormData>();

  const handleSendTest = async () => {
    const result = await trigger('template.email');
    if (!result) return;

    if (!template) return;
    triggerSendTestModal({
      onConfirm: async (recipient) => {
        await apiService.message.sendMarkdownEmail({
          recipientId: recipient.uid,
          template: template,
          notificationSettingsLink,
        });
      },
    });
  };

  return (
    <button
      type='button'
      className='btn text-sms text-secondary'
      onClick={handleSendTest}
    >
      Send Test
    </button>
  );
}

function MessageTemplateEmailPreviewBodyLayout(props: {
  showHtml: boolean;
  children: React.ReactElement;
}) {
  if (!props.showHtml) return props.children;

  const { html } = renderEmail(props.children);

  return (
    <div className='w-full h-full overflow-x-scroll whitespace-pre text-sms'>
      {html}
    </div>
  );
}

function MessageTemplateEmailPreview(
  props: MessageTemplateEditorEmailFieldsProps & {
    template: ModelsMessageTemplateEmail | null;
    notificationSettingsLink?: string | null;
  }
) {
  const { template, notificationSettingsLink } = props;

  const [showHtml, setShowHtml] = useState(false);

  const icon =
    MESSAGE_TEMPLATE_EMAIL_FROM_LIST.find(
      (config) => config.from.address === template?.from?.address
    )?.icon || config.slack.botIconURL;

  return (
    <div className='w-full'>
      <div className='w-full flex items-center justify-between mb-2'>
        <div className='flex items-center gap-4'>
          <p className='font-bold'>Preview</p>
          <label className='flex items-center gap-2'>
            <input
              type={'checkbox'}
              className='checkbox-dark'
              checked={showHtml}
              onChange={(event) => setShowHtml(event.target.checked)}
            />
            <p className='text-sms'>Show HTML</p>
          </label>
        </div>
        <SendTestButton
          template={template}
          notificationSettingsLink={notificationSettingsLink}
        />
      </div>
      <div className='w-full p-3 pb-8 border border-secondary rounded-xl'>
        <div className='flex flex-row items-center gap-2'>
          <img
            src={icon}
            alt='luna park'
            className='w-8 h-8 border border-[#2E2E2E]'
          />
          <div>
            <p className='flex items-end gap-1'>
              <span className='font-bold text-sms'>
                {template?.from.name || 'Luna Park'}
              </span>
              {template?.from.address && (
                <span className='text-xs text-secondary'>
                  {`<${template.from.address}>`}
                </span>
              )}
            </p>
            <p className='text-sm'>{template?.subject}</p>
          </div>
        </div>
        <div className='mt-2 w-full min-h-70 pl-10 text-sms text-left flex flex-col gap-5'>
          <MessageTemplateEmailPreviewBodyLayout showHtml={showHtml}>
            <EmailTemplateMarkdown
              body={template?.body || ''}
              buttons={template?.buttons as never}
              notificationSettingsLink={notificationSettingsLink}
            />
          </MessageTemplateEmailPreviewBodyLayout>
        </div>
      </div>
    </div>
  );
}

function MessageTemplateEmailFromField() {
  const { setValue, getValues } = useFormContext<MessageTemplateFormData>();

  const styles = useInstance(() =>
    buildReactSelectStyles<ModelsEmailAddress>({
      override: { control: { height: 48 } },
    })
  );

  const {
    field: { value, onChange },
  } = useController<MessageTemplateFormData, 'template.email.from'>({
    name: 'template.email.from',
  });

  return (
    <div>
      <div className='mb-2 font-bold'>From</div>
      <Select<ModelsEmailAddress>
        options={MESSAGE_TEMPLATE_EMAIL_FROM_LIST.filter(
          (config) => !config.disabled
        ).map((config) => config.from)}
        value={value}
        classNamePrefix='select-box-v2'
        className='w-56'
        styles={styles}
        placeholder='Choose from...'
        onChange={(option) => {
          onChange(option);

          const config = MESSAGE_TEMPLATE_EMAIL_FROM_LIST.find(
            (c) => c.from === option
          );
          if (config) {
            const replayTo = getValues('template.email.replyTo');
            if (!replayTo || replayTo.length === 0) {
              setValue('template.email.replyTo', config.replyTo);
            }
            setValue('template.email.cc', config.cc);
            setValue('template.email.bcc', config.bcc);
          }
        }}
        formatOptionLabel={(o) => (
          <div>
            <p className='text-sms'>{o.name}</p>
            <p className='text-3xs text-secondary'>{o.address}</p>
          </div>
        )}
      />
    </div>
  );
}

function MessageTemplateEmailReplyField() {
  const {
    field: { value, onChange },
    fieldState,
  } = useController<MessageTemplateFormData, 'template.email.replyTo'>({
    name: 'template.email.replyTo',
    rules: {
      validate: (value) => {
        if (!value || value.length === 0) return true;
        return value.every((v) => v.match(EMAIL_PATTERN));
      },
    },
  });

  return (
    <div className='flex-1'>
      <div className='mb-2 font-bold'>Reply To</div>
      <input
        className={`${fieldState.error ? 'field-error' : 'field'} mb-0`}
        type='text'
        defaultValue={value?.join(', ') || ''}
        onBlur={(event) =>
          onChange(event.target.value.split(',').map((v) => v.trim()))
        }
        placeholder='Separate multiple emails with commas'
      />
    </div>
  );
}

function MessageTemplateEmailSubjectField() {
  const {
    field: { value, onChange },
    fieldState,
  } = useController<MessageTemplateFormData, 'template.email.subject'>({
    name: 'template.email.subject',
    rules: { required: true, maxLength: 100 },
  });

  return (
    <div className='flex-1'>
      <div className='font-bold mb-2'>Subject</div>
      <input
        className={`${fieldState.error ? 'field-error' : 'field'} mb-0`}
        type='text'
        value={value}
        onChange={onChange}
        placeholder='Must be 1 to 100 characters'
      />
    </div>
  );
}

function MessageTemplateEmailBodyField(
  props: MessageTemplateEditorEmailFieldsProps
) {
  const {
    field: { value: body, onChange },
    fieldState,
  } = useController<MessageTemplateFormData, 'template.email.body'>({
    name: 'template.email.body',
    rules: { required: true },
    defaultValue: '',
  });

  return (
    <MessageTemplateMarkdownInput
      value={body}
      onChange={onChange}
      vars={props.vars}
      error={fieldState.error}
    />
  );
}

export interface MessageTemplateEditorEmailFieldsProps {
  vars?: Record<string, string>;
}

export function MessageTemplateEditorEmailFields(
  props: MessageTemplateEditorEmailFieldsProps
) {
  const template = useWatch<MessageTemplateFormData, 'template.email'>({
    name: 'template.email',
  });

  const translatedTemplate = useMemo(() => {
    if (!template) return null;
    return MessageTemplateUtils.TranslateTemplateEmail(template, props.vars);
  }, [props.vars, template]);

  return (
    <div className='w-full flex gap-4'>
      <div className='w-1/2 flex flex-col gap-4'>
        <div className='w-full flex gap-2'>
          <MessageTemplateEmailFromField />
          <MessageTemplateEmailReplyField />
        </div>
        <MessageTemplateEmailSubjectField />
        <MessageTemplateEmailBodyField {...props} />
        <MessageTemplateEmailButtonsField />
      </div>
      <div className='w-1/2'>
        <MessageTemplateEmailPreview
          template={translatedTemplate}
          notificationSettingsLink={
            props.vars ? props.vars['notificationSettingsLink'] : null
          }
          {...props}
        />
      </div>
    </div>
  );
}
