import { type ModelsEmailAddress } from '@lp-lib/api-service-client/public';

export interface MessageTemplateEmailFromConfig {
  from: ModelsEmailAddress;
  icon?: string;
  replyTo?: string[];
  bcc?: string[];
  cc?: string[];
  disabled?: boolean;
}

export const MESSAGE_TEMPLATE_EMAIL_FROM_LIST: MessageTemplateEmailFromConfig[] =
  [
    {
      from: {
        name: 'Luna Park',
        address: 'no-reply@mail.golunapark.com',
      },
      replyTo: ['support@lunapark.com'],
    },
    {
      from: {
        name: 'Melanie',
        address: 'no-reply@mail.golunapark.com',
      },
      replyTo: ['melanie@lunapark.com'],
    },
    {
      from: {
        name: 'Arlen',
        address: 'no-reply@mail.golunapark.com',
      },
      replyTo: ['arlen@lunapark.com'],
    },
    {
      from: {
        name: 'Arlen Marmel',
        address: 'arlen@lunaparkmail.com',
      },
      icon: 'https://lh3.googleusercontent.com/a-/ALV-UjUoga98WxzRHiVnirZOxhyg0vcybkpRovt3eF53OdEL9Jjbq90',
      bcc: ['arlen@lunaparkmail.com'],
      // disabled: true,
    },
  ];
